.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pages {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/indie-stry.appspot.com/o/images%2Fhome-page.png?alt=media&token=a1fe4683-73c8-4ce8-83c8-df19ceb604da&_gl=1*10jf5qu*_ga*MTk1NTEzNDk2NC4xNjkxNTQxNjA4*_ga_CW55HF8NVT*MTY5OTMwNjYyOS4yMDQuMS4xNjk5MzA3MzMxLjYwLjAuMA..");
  background-size: cover;
  color: "white";
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: "#E7E0DB";
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
