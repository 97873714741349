:root {

  --primary-text-color1 : #E7E0DB
    /* --primary-text-color1: #204B3D;
    --primary-text-color2: #E3E1D9;
    --bg-color: #FAF2EC;
    --bg-color2: #204B3D; 
    --profilesec-text-color: #FFEDD9;
    --skilltag-color: #44534F;
    --about-sec-bg-color: #E3E1D7;
    --footer-bg: #2A9D8F;
    --footer-text: #263045 */
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}