@import url("https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap");

.profileSecBg {
  background-color: var(--bg-color2);
  padding: 2vh clamp(5px, 8vw, 30px) 0 clamp(5px, 8vw, 30px);
}

.profileSec {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  color: var(--profilesec-text-color);
}

.profileCards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 2%;
  margin-bottom: 1%;
  /* gap: 2%; */
}

.profileSec > div:nth-child(1) {
  font-size: clamp(20px, 4vw, 50px);
  font-family: "Lato";
  font-weight: 900;
}

.seeMore {
  background-color: var(--profilesec-text-color);
  color: var(--bg-color2);
  display: inline-;
  font-size: clamp(10px, 2vw, 35px);
  width: clamp(50px, 12vw, 200px);
  border-radius: 30px;
  padding: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2vh;
}
