@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@font-face {
  font-family: "Lato";
  font-weight: normal;
  font-style: normal;
}

.pastWorkContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: start; /* Align items at the top of each cell */
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px; /* Add margin for spacing */
}

.applyContainer {
  width: 100%;
  max-width: 800px; /* Set a maximum width to avoid overly wide content */
  margin: 0 auto;
  padding: 20px;
}

.applyStack {
  width: 100%;
}
.buttonHeading {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
}
.applyHeading {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2vh;
}

.submitButton {
  margin-right: auto;
  margin-left: auto;
  margin-top: 2vh;
  /* color: var(--primary-text-color2); */
  /* background-color: var(--primary-text-color1); */
  border-radius: 35px;
  font-size: clamp(15px, 1.4vw, 40px);
  width: auto;
  padding: 1.4vh;
  font-weight: 500;
}

/* Add media query for smaller screens */
@media (max-width: 768px) {
  .applyStack {
    padding: 0; /* Reduce padding for smaller screens */
  }

  /* Adjust individual form elements as needed */
  .applyHeading {
    font-size: 36px; /* Decrease font size for smaller screens */
  }
}
